/*
* Arttu Lehtola 
* old-node-landing page
*/

@import url('https://fonts.googleapis.com/css2?family=Qwitcher+Grypen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');

#otsikko {
    font-family: 'Qwitcher Grypen';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: white;
    font-size: 10rem;
}
#alaOtsikko {
    color: white;
    font-size: 2.5rem;
    font-family: 'Merriweather';
}